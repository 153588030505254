import { makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import * as React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

interface Props {
  className?: string;
  defaultValue?: string;
  label: string;
  values?: string[];
  control: Control;
  error?: FieldError | undefined;
  name: string;
  rules?: any;
  type?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  onChanged?: (val: string) => void;
}

const ZTextFormFieldDropdown: React.FC<Props> = ({ label, className, defaultValue, values, control, error, name, variant, rules, onChanged }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.select} ${className}`}>
      <Controller
        key={name}
        rules={rules ?? { required: 'This field is required' }}
        autoComplete='false'
        label={label}
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        as={
          <TextField
            select
            fullWidth
            margin='dense'
            variant={(variant ?? 'outlined') as any}
            name={name}
            error={error?.message ? true : false}
            helperText={error?.message ? error.message : undefined}
          >
            {values &&
              values.map((value: string, i) => (
                <MenuItem
                  key={i}
                  value={value}
                  onClick={() => {
                    if (onChanged) onChanged(value);
                    console.log(value);
                  }}
                >
                  {value}
                </MenuItem>
              ))}
          </TextField>
        }
      />
    </div>
  );
};

ZTextFormFieldDropdown.defaultProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formControl: {
    width: '100%'
  },
  select: {}
}));

export default ZTextFormFieldDropdown;
