import { Button, Container, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import ZFooter from '../components/ZFooter';
import ZHeading from '../components/ZHeading';
import ZSEO from '../components/ZSeo';
import ZTextFormFieldDropdown from '../components/ZTextFormFieldDropdown';
import ZTextFormField from '../components/ZTextFromField';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  service: string;
  howDidYouHearAboutUs: string;
  anyThingElse: string;
};

const PageContactUs: FC = () => {
  const { handleSubmit, errors, control, getValues, reset } = useForm<FormData>({ mode: 'onSubmit', reValidateMode: 'onChange' });
  const classes = useStyles();

  const onSubmit = handleSubmit(async (data: FormData) => {
    console.log(data);

    try {
      const response = await axios.post('https://codememory-dokdmlhsoq-uc.a.run.app/shannakayFindlater/contact', {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        service: data.service,
        howDidYouHearAboutUs: data.howDidYouHearAboutUs,
        anyThingElse: data.anyThingElse
      });

      if (response.status === 200) {
        reset({
          firstName: '',
          lastName: '',
          email: '',
          service: '',
          howDidYouHearAboutUs: '',
          anyThingElse: ''
        });
        toast('Email sent!', { autoClose: 7000 });

        console.log(response);
      }
    } catch (error) {
      toast('Error submitting the form!', { autoClose: 7000 });
      console.log(error);
    }
  });

  return (
    <>
      <ZSEO title='Meet The Team' />
      <ZHeading firstText={'Contact'} secondText={'US'} />
      <Container className={classes.container} maxWidth='md'>
        <Grid container>
          <Grid item xs={12} md={6}>
            <ZTextFormField className={classes.textField} defaultValue={getValues().firstName} error={errors.firstName} label='First name' name='firstName' control={control} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ZTextFormField className={classes.textField} defaultValue={getValues().lastName} error={errors.lastName} label='Last name' name='lastName' control={control} />
          </Grid>
          <Grid item xs={12}>
            <ZTextFormField className={classes.textField} defaultValue={getValues().email} error={errors.email} label='Email' name='email' control={control} />
          </Grid>

          <Grid item xs={12}>
            <ZTextFormFieldDropdown
              values={services}
              className={classes.textField}
              defaultValue={getValues().service}
              error={errors.service}
              label='Which service do you need?'
              name='service'
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <ZTextFormFieldDropdown
              values={hearAboutMe}
              className={classes.textField}
              defaultValue={getValues().howDidYouHearAboutUs}
              error={errors.howDidYouHearAboutUs}
              label='How did you hear about us?'
              name='howDidYouHearAboutUs'
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <ZTextFormField
              className={classes.textField}
              defaultValue={getValues().anyThingElse}
              error={errors.anyThingElse}
              rows={4}
              label='Anything else?'
              name='anyThingElse'
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justify='center'>
          <Button type='submit' onClick={onSubmit} className={classes.buttonSave} size='medium' color='primary' fullWidth variant='contained'>
            Send
          </Button>
        </Grid>
      </Container>
      <div className={classes.spacer} />

      <ZFooter />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  textField: {
    margin: '10px 12px'
  },
  spacer: {
    marginBottom: 520
  },
  buttonSave: {
    width: '100%',
    margin: '15px 5px',
    maxWidth: 200
  }
}));

const services = [
  'Website Copy',
  'Resume Writing',
  'Curriculum Vitae',
  'Cover Letter',
  'Academic Support',
  'Youtube and Podcast Description',
  'Business Card',
  'Event Materials',
  'Print Ads',
  'Brochures',
  'Billboards',
  'TV Script',
  'Radio script',
  'Digital Script',
  'Digital Campaign Plans and Strategy',
  'Marketing Material',
  'Brand Consultation and Guidance',
  'Sponsorship Proposal',
  'Online Articles',
  'Social Media Content',
  'Creative Writing',
  'Proofreading',
  'Grant Applications',
  'Outreach Email'
];

const hearAboutMe = ['Google', 'Facebook', 'Instagram', 'Client Referal', 'Family/Friend/Colleague'];

export default PageContactUs;
